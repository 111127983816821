* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
	font-weight: 300;
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	line-height: rem($baseLineHeight);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight);

	&:not(.javascript) {

		* {
			transition:none!important;
		}
	}
}

iframe, [data-iframe] {
	border: none;
	width: 100%;

	&[data-src] {
		@extend %lazyloading;
	}
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
	
	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	font-weight: 700;
	font-size: rem(22px);
	line-height: rem(33px);
	color:$dark;
	margin-bottom: rem(33px);
	text-transform: uppercase;

	span {
		text-transform: none;
		display: block;
		font-weight: 300;
		font-size: rem(18px);
		line-height: rem(24px);
	}

	@include breakpoint(huge) {
		margin-bottom: rem(50px);
		font-size: rem(36px);
		line-height: rem(47px);

		span {
			font-size: rem(26px);
			line-height: rem(37px);
		}
	}
}

h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: 700;
	font-size: rem(22px);
	line-height: rem(33px);
	color:$dark;
	@extend .marginBottom;
	text-transform: uppercase;

	span {
		text-transform: none;
		display: block;
		font-weight: 300;
		font-size: rem(18px);
		line-height: rem(24px);
	}

	&:not(.small) {

		@include breakpoint(huge) {
			font-size: rem(30px);
			line-height: rem(41px);

			span {
				font-size: rem(22px);
				line-height: rem(31px);
			}
		}
	}
}

@include breakpoint(huge) {

	body.datenschutzerklaerung {

		h4 {
			font-size: rem(22px);
			line-height: rem(33px);
		}

		h5 {
			font-size: rem(20px);
			line-height: rem(31px);
		}

		h6 {
			font-size: rem(18px);
			line-height: rem(29px);
		}
	}
}

/**
 * Links
 */
a {
	color: $primary;
	word-wrap: break-word;
	text-decoration: underline;

	&:focus,
	&:hover,
	&:active {
		color: $primary;
		text-decoration: none;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr, .hr {
	display:block;
	width:100%;
	background:none;
	height:1px;
	border:none;
	border-bottom:1px solid $border;
	padding:calc(#{rem(40px)} - #{rem($baseLineHeight)}) 0 0 0;
	margin:0 0 rem(40px);

	@include breakpoint(large) {
		margin:0 0 rem(70px);
		padding:calc(#{rem(70px)} - #{rem($baseLineHeight)}) 0 0 0;
	}
}

ul,
ol {
  list-style: none;

  &.styledList {

    li {
      padding-left: rem(19px);
      margin-bottom: rem(7px);

      &:before {
      	color:$primary;
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-19px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background:$medium;
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;

			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	list-style-type: none;
	counter-reset: item;

	li {
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		display: table;
		counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {
			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
	}
}

/**
 * Tables
 */

table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: rem($baseFontSize);

  @include breakpoint(small, max) {
    overflow-x: auto;
    display: block;
  }

  caption {
    text-align: left;
    padding: rem($baseGap) rem($baseGap) rem(25px);
    display: table-caption;
    font-weight: 700;
    font-size: 1.2rem;
  }

  thead {
    border-collapse: collapse;
    display: table-header-group;
  }

  tbody {
    width: 100%;
    overflow-x: scroll;
    display: table-row-group;

    tr {
      padding: rem($baseGap) 0;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $medium;
      }
    }
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: rem($baseGap);
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(small, max) {
    td {
      min-width: 50vw;
    }
  }
}
