// Example font-face for Roboto
// @include fontFaceV2(
//     $fontName: "Roboto", 
//     $fileName: "roboto-v19-latin", 
//     $weights: ("400", "700", "900", "900|italic"), 
//     $types: all
// );

@include fontFaceV2(
	$fontName: "icomoon",
	$fileName: "icomoon",
	$types: (woff2, woff),
	$style: normal
);

@include fontFaceV2(
	$fontName: "SpartanMB",
	$fileName: "spartanMB",
	$types: (woff2, woff),
	$weights: ("300", "500", "700"), 
	$style: all
);