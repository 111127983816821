
// HEADER
//////////////////////////////

#header {
	display: block;
	width: 100%;
	max-width: 100%;
	padding-top:rem($quickbar);

	@include breakpoint(giant) {
		padding-top:rem(140px);
	}

	#fixedContainer {

		.branding {

			@include breakpoint(giant, max) {
				display: none;
			}
		}

		@include breakpoint(giant) {
			z-index: 100;
			background:$light;
			display: flex;
			align-items: center;
			height: rem(140px);
			position: fixed;
			top:0;
			left:0;
			width: 100%;
			transition:height $time, box-shadow $time;

			&.fix {
				height: rem(130px);
				box-shadow:0 0 10px rgba(black,0.2);

				.branding {
					img {
						width: 80px!important;
					}
				}
			}

			.branding {
				img {
					transition: width $time;
					width: 100px!important;
				}
			}
		}

		.topContainer {
			display: none;

			@include breakpoint(giant) {
				display: flex;
				justify-content: space-between;

				li {
					font-size: rem(15px);
					display: flex;
					align-items: center;
					line-height: 100%;

					a {
						color:$dark;
						text-decoration: none;
						transition:color $time;

						&:hover {
							color:$primary;
						}

						&.facebook {
							position: relative;
							transition: transform $time;

							&:hover {
								transform:translateY(-2px);
							}
						}
					}

					i {
						position: relative;
						background: $primary;
						border-radius: 100%;
						display: inline-flex;
						width: 24px;
						height: 24px;
						color:$light;
						justify-content: center;
						align-items: center;
						font-size: rem(17px);
						margin-right: rem(10px);

						&:before {
							position: relative;
							display: block;
						}

						&.icon-phone {
							font-size: rem(17px);
						}

						&.icon-mobile {
							font-size: rem(24px);
						}

						&.icon-paper-plane {
							font-size: rem(20px)
						}
					}
				}
			}
		}
	}

	.teaserContainer {
		
		.col {
			position: relative;
		}

		.contentBox {
			padding-top:rem(40px);
			padding-bottom:rem(40px);

			@include breakpoint(medium) {
				padding-top:rem(80px);
				padding-bottom:rem(80px);
			}

			@include breakpoint(giant) {
				padding-left:rem(63px);
				padding-top:rem(120px);
				padding-bottom:rem(120px);
			}

			@include breakpoint(full) {
				padding-top:rem(100px);
				padding-bottom:rem(100px);
			}
		}

		.image {
			background: {
				size:cover;
				position: center;
				image:image-url('layout/tiny-bg-header.jpg');
			};
			padding-bottom: 61.43%;
			position: relative;
			transform:translateX(-$baseGap);
			width: calc( 100% + #{rem($baseGap)} * 2 );

			@include breakpoint(tiny) {
				background-image:image-url('layout/medium-bg-header.jpg');
			}

			@include breakpoint(medium) {
				background-image:image-url('layout/tiny-bg-header.jpg');
			}

			@include breakpoint(large) {
				background-image:image-url('layout/bg-header.jpg');
			}

			@include breakpoint(medium) {
				padding-bottom: 0;
				transform:none;
				position: absolute;
				height: 100%;
				top:0;
				right:rem($baseGap);
				width: calc( 100% - #{rem($baseGap)});
			}	

			@include breakpoint(full) {
				width: calc( 100% + ( (100vw - #{rem($rowMaxWidth)}) / 2 ) - #{rem($baseGap)});
			}
		}

		font-size: rem(14px);
		line-height: 100%;

		@include breakpoint(medium) {
			font-size: rem(16px);
		}

		@include breakpoint(giant) {
			font-size: rem(20px);
		}

		.large {
			font-weight: 700;
			font-size: rem(22px);
			line-height: rem(33px);
			color:$dark;
			margin-bottom: rem(19px);
			text-transform: uppercase;

			span {
				display: block;
				font-weight: 300;
				font-size: rem(18px);
				line-height: rem(30px);
			}

			@include breakpoint(huge) {
				font-size: rem(40px);
				line-height: rem(48px);

				span {
					font-size: rem(34px);
					line-height: rem(44px);
				}
			}
		}
	}

	.subpage {
		display: block;
		padding-bottom: rem(100px);
		position: relative;
		overflow:hidden;
		background: image-url('index/firmengelaende.jpg') no-repeat center center;
		background-size: cover;

		@include breakpoint(medium) {
			padding-bottom: rem(150px)
		}

		@include breakpoint(large) {
			padding-bottom: rem(200px)
		}

		body.cmsBackend & {
			display: none;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	width: 100%;
	max-width: 100%;
	overflow:hidden;
	display: block;
	padding-top:rem(40px);
	padding-bottom:calc(#{rem(40px)} - #{rem($baseLineHeight)});

	@include breakpoint(medium) {
		padding-top:rem(90px);
		padding-bottom:calc(#{rem(90px)} - #{rem($baseLineHeight)});
	}

	b, strong {
		font-weight: 500;
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	ul.timeline, #timeline {
		li {
			position: relative;
			display: flex;

			&:last-of-type {
				.textBox {
					margin-bottom: 0;
				}
			}

			@include breakpoint(medium, max) {
				justify-content: flex-end;

				.textBoxContainer {
					padding-left:50px;
					flex-direction: row-reverse;

					&:after {
						left:9px;
					}

					&:before {
						left:0;
					}
				}

				.textBox {
					&:after {
						right:100%;
						border-width: 12.5px 25px 12.5px 0;
						border-color: transparent $medium transparent transparent ;
					}
				}
			}

			@include breakpoint(medium) {
				&:nth-of-type(odd) {
					.textBoxContainer {
						padding-right:50px;

						@include breakpoint(giant) {
							padding-right:100px;
						}

						&:before {
							right:-10px;

							@include breakpoint(giant) {
								right:-15px;
							}
						}

						&:after {
							right:0%;
						}
					}

					.textBox {

						&:after {
							left:100%;
							border-width: 12.5px 0 12.5px 25px;
							border-color: transparent transparent transparent $medium;
						}
					}
				}	

				&:nth-of-type(even) {
					justify-content: flex-end;

					.textBoxContainer {
						padding-left:50px;
						flex-direction: row-reverse;

						@include breakpoint(giant) {
							padding-left:100px;
						}

						&:after {
							right:100%;
						}

						&:before {
							left:-10px;

							@include breakpoint(giant) {
								left:-15px;
							}
						}
					}

					.textBox {
						&:after {
							right:100%;
							border-width: 12.5px 25px 12.5px 0;
							border-color: transparent $medium transparent transparent ;
						}
					}
				}
			}

			.textBoxContainer {
				width: 100%;
				flex-basis:100%;
				flex-shrink: 0;
				flex-grow: 0;
				display: flex;
				justify-content: space-between;
				position: relative;

				&:after {
					content:'';
					display: block;
					width: 1px;
					background:$border;
					flex-shrink: 0;
					position: absolute;
					top:0;
					height: 100%;
				}

				&:before {
					position: absolute;
					top:33px;
					z-index: 1;
					width: 20px;
					height: 20px;
					border-radius: 100%;
					background:$primary;
					content:'';

					@include breakpoint(giant) {
						width: 30px;
						height: 30px;
						top:30px;
					}
				}

				@include breakpoint(medium) {
					width: 50%;
					flex-basis:50%;					
				}				
			}

			.textBox {
				width: 100%;
				margin-bottom: rem($baseLineHeight);
				background:$medium;
				position: relative;
				padding:rem($baseLineHeight) rem($baseLineHeight) 0 rem($baseLineHeight);

				&:after {
					position: absolute;
					top:32px;
					content:'';
					width: 0;
					height: 0;
					border-style: solid;
				}

				@include breakpoint(giant) {
					margin-bottom: rem(80px);
					padding:rem($baseLineHeight)*2 rem($baseLineHeight)*2 rem($baseLineHeight) rem($baseLineHeight)*2;
				}

				
			}
		}
	}

	// Timeline
	#timeline {
	    position: relative;
	    transition:all .4s ease;
	    &:before {
	        content:"";
	        width: 3px;
	        height: 100%;
	        background: $secondary;
	        left: 0;
	        top: 0;
	        position: absolute;
	        @include breakpoint(small) {
	            left: 50%;
	        }
	    }
	    &:after {
	        content: "";
	        clear: both;
	        display: table;
	        width: 100%;
	    }
	    .timeline-item {
	        margin-bottom: 50px;
	        position: relative;
	        @extend .clearfix;
	        .timeline-icon {
	            background: $primary;
	            width: 40px;
	            height: 40px;
	            position: absolute;
	            top: 0;
	            left: 0;
	            overflow: hidden;
	            margin-left: -20px;
	            border-radius:50%;
	            @include breakpoint(small) {
	                left: 50%;
	                width: 50px;
	                margin-left: -23px;
	                height: 50px;
	            }
	 
	            svg {
	                position: relative;
	                top: 10px;
	                left: 10px;
	                color: $light;
	                fill: currentColor;
	                @include breakpoint(small) {
	                    top: 14px;
	                    left: 14px;
	                }
	            }
	        }
	        .timeline-content {
	            width: 90%;
	            background: #fff;
	            padding: 30px;
	            box-shadow: 0 0 10px rgba(0,0,0,0.3);
	            border-radius:5px;
	            transition: all .3s ease;
	            float: right;
	            @include breakpoint(small) {
	                width: 45%;
	                float: none;
	            }
	            h2 {
	                padding: 15px 30px;
	                background: $primary;
	                color: $light;
	                margin: -30px -30px rem(40px) -30px;
	                border-radius:3px 3px 0 0;
	            }
	            &:before {
	                content: '';
	                position: absolute;
	                left: 10%;
	                top: 20px;
	                width: 0; 
	                height: 0; 
	                margin-left: -6px;
	                border-top: 7px solid transparent;
	                border-bottom: 7px solid transparent; 
	                border-right: 7px solid $primary;
	                border-left:0; 
	                @include breakpoint(small) {
	                    left: 45%;
	                    margin-left: 0;
	                    border-left: 7px solid $primary;
	                    border-right: none;
	                }
	            }
	        }
	        &:nth-child(2n) .timeline-content {
	            @include breakpoint(small) {
	                float: right;
	                &:before {
	                    content: '';
	                    right: 45%;
	                    left: inherit;
	                    border-left: 0;
	                    border-right: 7px solid $primary;
	                }
	            }
	        }
	    }

	    // .textBoxContainer {
	    // 	width: 100%;
	    // 	flex-basis:100%;
	    // 	flex-shrink: 0;
	    // 	flex-grow: 0;
	    // 	display: flex;
	    // 	justify-content: space-between;
	    // 	position: relative;

	    // 	&:after {
	    // 		content:'';
	    // 		display: block;
	    // 		width: 1px;
	    // 		background:$border;
	    // 		flex-shrink: 0;
	    // 		position: absolute;
	    // 		top:0;
	    // 		height: 100%;
	    // 	}

	    // 	&:before {
	    // 		position: absolute;
	    // 		top:33px;
	    // 		z-index: 1;
	    // 		width: 20px;
	    // 		height: 20px;
	    // 		border-radius: 100%;
	    // 		background:$primary;
	    // 		content:'';

	    // 		@include breakpoint(giant) {
	    // 			width: 30px;
	    // 			height: 30px;
	    // 			top:30px;
	    // 		}
	    // 	}

	    // 	@include breakpoint(medium) {
	    // 		width: 50%;
	    // 		flex-basis:50%;					
	    // 	}				
	    // }
	}

	.highlightBox {
		padding:rem($baseLineHeight) rem($baseLineHeight) 0 rem($baseLineHeight);
		@extend .boxShadow;

		@include breakpoint(giant) {
			padding:rem($baseLineHeight)*2 rem($baseLineHeight)*2 rem($baseLineHeight) rem($baseLineHeight)*2;
		}
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.imageBox {
		position: relative;

		&.left {
			
			img {
				
				@include breakpoint(medium) {
					right:rem($baseGap);
				}
			}
		}

		&:not(.left) {
			
			img {

				@include breakpoint(medium) {
					left:rem($baseGap);
				}
			}
		}

		body:not(.cmsBackend) & {

			img {
				object-fit:cover;
				object-position: bottom right;

				@include breakpoint(medium) {
					padding-bottom: 0;
					transform:none;
					position: absolute;
					height: 100%!important;
					top:0;
					width: calc( 100% - #{rem($baseGap)})!important;
				}	

				@include breakpoint(full) {
					max-width: none;
					width: calc( 100% + ( (100vw - #{rem($rowMaxWidth)}) / 2 ) - #{rem($baseGap)})!important;
				}
			}
		}
	}

	.paddingBox {
		@include breakpoint(medium) {
			padding-top:rem(60px);
			padding-bottom:calc(#{rem(60px)} - #{rem($baseLineHeight)});
		}

		@include breakpoint(giant) {
			padding-top:rem(80px);
			padding-bottom:calc(#{rem(80px)} - #{rem($baseLineHeight)});
		}

		@include breakpoint(full) {
			padding-top:rem(100px);
			padding-bottom:calc(#{rem(100px)} - #{rem($baseLineHeight)});
		}
	}

	.contactBox {
		position: relative;

		> a {
			margin-bottom: 0!important;
		}

		.innerBox {
			
			span {
				display: block;
				position: relative;
				line-height: 100%;

				&.name {
					border-top:12px solid $primary;
					font-size: rem(16px);
					padding:rem(20px) 0 0 0;

					&:before {
						position: absolute;
						top:-7px;
						left:20px;
						width: 0;
						content:'';
						height: 0;
						border-style: solid;
						border-width: 0 7px 7px 7px;
						border-color: transparent transparent $light transparent;
					}
				}

				&.position {
					font-size: rem(16px);
					padding:rem(8px) 0;
				}

				&.phone {
					font-size: rem(16px);
					color:$dark;
					text-decoration: none;
					transition:color $time;
					padding-top:rem(2px);

					a {
						color:$dark;
						text-decoration: none;
					}

					i {
						margin-right: rem(4px);
					}
				}

				@include breakpoint(large) {

					&.name {
						font-size: rem(22px);
					}
					
					&.position {
						padding:rem(10px) 0;
						font-size: rem(20px);
					}

					&.phone {
						font-size: rem(18px);
					}		
				}
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	display: block;
	width: 100%;
	max-width: 100%;

	.teaserContainer {
		background:$medium;
		padding-top:rem(40px);
		padding-bottom:rem(40px);
			
		@include breakpoint(small) {
			> .row {

				> .col:first-of-type {
					padding-right:0;
				}
			}
		}

		@include breakpoint(medium) {
			padding-top:rem(80px);
			padding-bottom:rem(80px);
		}

		@include breakpoint(giant) {
			padding-top:rem(90px);
			padding-bottom:rem(90px);
		}

		.col {

			@include breakpoint(small) {
				display: flex;
				flex-wrap:wrap;
			}
		}

		.mobileMarginBottom {
			margin-bottom: rem($baseGap)*2;

			@include breakpoint(small) {
				margin-bottom: 0;
			}
		}

		.DesktopMarginBottom {

			@include breakpoint(small) {
				margin-bottom: rem(44px);
			}
		}

		a {
			width: 100%;
			text-decoration: none;
			color:$dark;
			display: flex;
			position: relative;
			flex-direction: column;

			body:not(.isTouch) & {

				&:hover {

					img {
						transform:scale(1.1);
					}

					i {

						&:before {
							transform:rotate(-90deg);
						}
					}
				}
			}
			
			div {
				width: 100%;
				position: relative;
				flex-grow:1;
				overflow: hidden;
    			align-self: stretch;

    			picture {
    				min-height: 100%;
    				display: block;
					position: relative;
					height: 100%;
					width: 100%;
    			}

				img {
					transform:scale(1);
					transition:transform $time;
					// object-position: 50% 50%;
					display: block;
					// min-width: 100%;
					// min-height: 100%;
					// width: 100%;
					height: 100%;
					// max-width: 100%;
					// max-height: 100%;
					object-fit: cover;
				}
			}

			span {
				flex-grow:0;
				width: 100%;
				text-align: center;
				position: relative;
				font-size: rem(14px);
				line-height: rem(23px);
				padding:rem(20px) rem(7px) rem(40px);
				background:$light;
				display: block;

				@include breakpoint(medium) {
					font-size: rem(13px);
					line-height: rem(22px);
				}

				@include breakpoint(large) {
					font-size: rem(16px);
					line-height: rem(25px);
				}

				@include breakpoint(huge) {
					font-size: rem(22px);
					padding:rem(50px) rem(7px);
					line-height: 100%;
				}
			}

			i {
				position: absolute;
				bottom:-9px;
				left:50%;
				transform:translateX(-50%);
				transition:background $time, transform $time;
				background: $primary;
				border-radius: 100%;
				display: inline-flex;
				width: 36px;
				height: 36px;
				color:$light;
				justify-content: center;
				align-items: center;
				font-size: rem(30px);

				&:before {
					position: relative;
					display: block;
					transition:transform $time;
				}
			}
		}
	}

	.contactContainer {
		font-size: rem(26px);
		line-height: rem(37px);
		padding-top:rem(40px);
		padding-bottom:rem(40px);

		@include breakpoint(medium) {
			padding-top:rem(80px);
			padding-bottom:rem(80px);
		}

		@include breakpoint(giant) {
			padding-top:rem(120px);
			padding-bottom:rem(120px);
		}

		@include breakpoint(full) {
			padding-top:rem(180px);
			padding-bottom:rem(180px);
		}

		.large {
			font-size: rem(36px);
			line-height: rem(47px);
		}

		.link {
			display: inline-flex;
			align-items: center;
			font-size: rem(20px);
			line-height: rem(28px);
			color:$dark;
			text-decoration: none;

			&:hover {
				
				.icon {
					transform: translateX(3px);
					background:$dark;
				}
			}

			span {
				display: block;
				transform: translateY(-1px);
			}

			.icon {
				position: relative;
				transition:background $time, transform $time;
				background: $primary;
				border-radius: 100%;
				display: inline-flex;
				width: 27px;
				height: 27px;
				color:$light;
				justify-content: center;
				align-items: center;
				font-size: rem(22px);
				margin-right: rem(13px);

				&:before {
					transform: translateX(1px);
				}
			}
		}
	}

	.siteMapContainer {
		
		text-align: center;

		@include breakpoint(large, max) {
			font-size: rem(14px);
			line-height: rem(25px);
		}
				
		@include breakpoint(medium) {
			text-align: left;
		}

		.col {
			position: relative;
			min-height: rem(250px);
		}

		iframe {
			position: absolute;
			top:0;
			height: 100%;
			right:rem($baseGap);
			width: calc( 100% - #{rem($baseGap)} * 2 );

			@include breakpoint(medium) {
				width: calc( 100% - #{rem($baseGap)});
			}	

			@include breakpoint(full) {
				width: calc( 100% + ( (100vw - #{rem($rowMaxWidth)}) / 2 ) - #{rem($baseGap)});
			}
		}

		.contentBox {
			padding-top:rem(40px);
			padding-bottom:rem(20px);

			@include breakpoint(medium) {
				padding-top:rem(80px);
				padding-bottom:rem(80px);
			}

			@include breakpoint(giant) {
				padding-top:rem(120px);
				padding-bottom:rem(120px);
			}

			@include breakpoint(full) {
				padding-top:rem(180px);
				padding-bottom:rem(180px);
			}
		}

		.marginBottom {
			margin-bottom: rem(20px);

			@include breakpoint(medium) {
				margin-bottom: rem(50px);
			}
		}

		.headline {
			display: block;
			font-size: rem(16px);
			line-height: rem(24px);

			@include breakpoint(huge) {
				font-size: rem(22px);
				line-height: rem(30px);
			}
		}

		dl {

			dt, dd {
				display: block;

				@include breakpoint(medium) {
					float: left;
				}
			}

			dt {
				font-weight: 700;

				@include breakpoint(medium) {
					width: rem(110px);
					font-weight: 300;
					clear:left;
				}
			}

			dd {
				margin-bottom: rem(10px);

				@include breakpoint(medium) {
					margin-bottom: 0;
				}
			}
		}

		.navi {
			display: none;

			@include breakpoint(medium) {
				display: flex;
				justify-content: space-between;

				li {

					a {
						text-decoration: none;
						color:$dark;
						transition:color $time;

						&:hover, &.active {
							color:$primary;
						}
					}
				}
			}
		}
	}
}

#fixedBottomBar {
	height: 40px;
	position: fixed;
	z-index: 999;
	background:$medium;
	color:$dark;
	bottom:0;
	left:0;
	width: 100%;
	display: flex;
	align-items: center;
	@extend .boxShadow;
	font-size: rem(13px);
	padding:0 rem($baseGap);

	li {
		margin-left: rem($baseGap);
		display: flex;
		border:1px solid $border;

		a {
			padding:rem(4px) rem($baseGap);
			color:inherit;
			text-decoration: none;
		}
	}
}
.dienstleistungenp {
	padding-top: 1rem;
	line-height: 1.6875rem;
}