// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 16px;

// Line height of main content (in px)
$baseLineHeight: 27px;

// Headline font sizes (in px)
$h1Size: 32px;
$h2Size: 28px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 18px;
$h6Size: 16px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    	"SpartanMB", sans-serif;
$displayFont: 	"SpartanMB", sans-serif;
$iconFont:   	"icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #fe0000; // red
$secondary: #e23030; // darken red
$light:     #fff;
$medium:    #f4f4f4;
$dark:      #000000;
$border:    #bbb;
$alert:     #D85D00;
$success:	#6bb23b;
$error:		#dc550c;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1520px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

$time: 0.3s;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

$iconMap: (
    search: "\f002",
    check: "\f00c",
    times: "\f00d",
    angle-down: "\f107",
    pdf: "\f1c1",
    user-circle: "\f2bd",
    exclamation: "\e90d",
    check-box: "\e957",
    clock: "\e968",
    envelope: "\e9b0",
    fax: "\e9b9",
    mobile: "\e9e3",
    arrow-left: "\ea1e",
    arrow-right: "\ea1f",
    arrow-up: "\ea21",
    angle-left: "\ea24",
    angle-right: "\ea2a",
    map-marker: "\ea41",
    mobile-ring: "\ea4b",
    paper-plane: "\ea6d",
    pencil: "\ea7a",
    phone: "\ea81"
);

// EXAMPLE FOR USE WITH ICONFONT
// $iconMap: (
//     times: "\e909",
//     minus: "\e90c",
//     angle-right: "\e902",
//     plus: "\e90a",
//     angle-up: "\e903",
//     exclamation: "\e90d"
// );


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
        center: (align-item: center), <-- resutl generates .tiny-center {align-item: center} for breakpoint tiny
        flex: (display: flex) <-- resutl generates .tiny-flex {display: flex} for breakpoint tiny
    )
*/
$gridMap: (
	default: (
        width:  em(320px, $bpContext),
        first:(order:-1),
        cols: (4,6,8),
        middle: (align-items:center),
        between: (justify-content:space-between),
        center: (justify-content:center)
    ),
    tiny: (
        width:  em(480px, $bpContext),
        cols: (4),
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (4,6,7,8),
        border: (border: solid 1px red)
    ),
    medium: (
        width:  em(760px, $bpContext),
        cols: (3,4,5,6,7,8,9),
        prefix: (1),
        last:(order:0),
        between: (justify-content:space-between)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (2,3,4,5,6,7,8,9,10),
        prefix: (1, 2,4)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (2,3,4,5,6,7,8,9,10),
        prefix: (1),
        middle: (align-items:center),
        between: (justify-content:space-between)
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (3,4,5,7,8,9)
    ),
    full: (
        width:  em(1520px, $bpContext),
        cols: (1,3,4,5,8)
    )
);