// SETTINGS

$quickbar: 50px;


#quickbar label[for="naviToggled"]:after,
#navigation,
#navigation nav ul.navi li a,
#navigation nav ul.navi li:not(.nav-back)>span,
#navigation nav ul.navi li.nav-back,
#navigation nav ul.navi li.hasSub>span,
#navigation nav ul.navi li.hasSub>span .sub,
#navigation nav ul.navi li.hasSub>a .sub,
#navigation nav ul.navi li.hasSub .sub,
#quickbar{transition:0.3s}


// MOBILE NAVIGATION

@include breakpoint(giant, max) {

	#navigation {
		min-width:70%;
		overflow:hidden;
		top:rem($quickbar);
		bottom:0;
		position:fixed;
	 	max-width:100%;
		right:0;
		background-color:white;
	 	z-index:1002;

	 	@include breakpoint(medium) {
			min-width:50%;
	 	}

		nav {

			ul {
				width:100%;
				overflow:hidden;
				height:calc(100vh - #{rem($quickbar)});

				&.open {
					overflow-y:auto;
					-webkit-overflow-scrolling:touch;
				}

				&.navi {
					overflow-y:auto;
					-webkit-overflow-scrolling:touch;

					li {
						display: flex;
						width: 100%;
						align-items: center;

						&.nav-back {
							position:relative;

							span {
								justify-content: flex-start;

								&:before {
									margin-right: rem(7px);
									transform:translateY(1px);
									font-family:$iconFont;
									font-weight: 400;
									content:"\ea24";
									display:inline-block
								}
							}							
						}

						&.hasSub {

							> a, > span {

								&:after {
									margin-left:0.5em;
									display:inline-block;
									font-family:$iconFont;
									font-weight: 400;
									content:"\ea2a";
								};
							}

							.sub {
								position:absolute;
								left:0;
								top:0;
								background:white;
								transform:translateX(100%);
								min-height: 100%;

								// &:before {
								// 	content:attr(data-parent-name);
								// 	display:block;
								// 	padding:1em rem($baseGap);
								// 	background-color:#f0f0f0;
								// }

								&.current {
									transform:translateX(0%);
								}
							}
						}

						a, span {
							width: 100%;
							align-items:center;
							justify-content:space-between;
							display: flex;
							text-decoration: none;
							color:$dark;
							padding:rem(9px) rem($baseGap);
							border-top: 1px solid rgba(black,0.1);
							font-size: rem(15px);
						}

						&.facebook {
							margin:0 0 rem(4px) rem($baseGap);

							a {
								padding:0;
								font-size: 0;
								width: 20px;
								height: 20px;
								background: {
									size:cover;
									position: center;
									image:image-url('layout/fb.svg');
								}
							}
						}

						&.kontakt, &.standort, &.impressum, &.datenschutzerklaerung {
							
							a, span {
								padding:rem(4px) rem($baseGap);
								border:none;
								font-size: rem(14px);
							}
						}

						&.facebook {
							border-top: 1px solid rgba(black,0.1);
							padding-top:rem(20px);
						}

						// active status

						a.active, &.hasSub>span.active, &.hasSub.active>a, &.hasSub.active>span {
							color:#fff;
							background-color:#2d2d2d;
						}

					}
				}
			}
		}
	}

	html.hidden-scroll {
		overflow:hidden
	}
}

// DESKTOP NAVIGATION

@include breakpoint(giant) {

	#navigation {
		margin-top:rem(13px);
		flex-grow:1;

		.navi {
			display: flex;
			justify-content: space-between;

			> li {

				@include breakpoint(full, max) {
					
					&.startseite {
						display: none;
					}
				}

				> a, > span {
					padding:rem(9px) 0;

					&:before {
						position: absolute;
						top:120%;
						width: 100%;
						height: 1px;
						content:'';
						background:$light;
						display: block;
						transition:opacity $time, visibility $time, top $time;
						opacity: 0;
						visibility: hidden;
					}

					&.active {

						&:before {
							opacity: 1;
							visibility: visible;
							top:100%;
						}
					}
				}

				&:hover, &.active {
					
					> a, > span {

						&:before {
							opacity: 1;
							visibility: visible;
							top:100%;
						}
					}
				}
			}

			li {
				position: relative;

				a, span {
					line-height: 100%;
					position: relative;
					display: flex;
					align-items: center;
					color:$dark;
					font-weight: 300;
					text-decoration: none;
					transition:color $time;
					font-size: rem(16px);
					white-space: nowrap;
				}

				&:hover, &.active {

					> a, > span {
						cursor: pointer;
						color:$primary;
					}
				}

				> a, > span {

					&.active {
						color:$primary;
					}
				}

				&.kontakt, &.standort, &.jobs, &.impressum, &.datenschutzerklaerung, &.nav-back, &.facebook {
					display: none;
				}

				&.hasSub {

					> a, > span {

						&:after {
							display: flex;
							position: relative;
							transform:translateY(1px) rotate(180deg);
							margin-left: rem(3px);
							font-family: $iconFont;
							color:$primary;
							content:'\ea21';
						};
					}
				}

				.sub {
					position: absolute;
					top:120%;
					pointer-events: none;
					left:0;
					min-width: 100%;
					background:$light;
					opacity: 0;
					visibility: hidden;
					box-shadow:0 0 10px rgba(black,0.2);
					transition: opacity $time, visibility $time, top $time;

					&.open {
						opacity: 1;
						visibility: visible;
						top:100%;
						pointer-events: auto;
					}

					li {

						&:not(:last-child) {

							a, span {
								border-bottom: 1px solid $medium;
							}
						}

						a, span {
							padding:rem(12px) rem(25px) rem(10px);
							transition:background $time, color $time;

							&:hover, &.active {
								background: $primary;
								color:$light;
							}
						}
					}
				}
			}
		}
	}
}


// MOBILE QUICKBAR

#naviToggled {
	display:none;
};

@include breakpoint(giant) {
	#quickbar {
		display: none;
	}
}

@include breakpoint(giant, max) {

	#quickbar {
		height:rem($quickbar);
		background-color:$light;
		color:$dark;
		display:flex;
		justify-content:space-between;
		align-items:center;
		padding:rem($baseGap);
		z-index:1000;
		position:fixed;
		left:0;
		width:100%;
		top:0;
		transform:translateY(0%);
		transition:transform $time;

		body.scrollingDown & {
			transform:translateY(-100%);
		}

		label[for="naviToggled"] {
			display:block;
			cursor:pointer;
			width:20px;
			height:20px;
			position:relative;
			overflow:hidden;

			&:after{
				content:"";
				position:fixed;
				left:0;
				width:200vw;
				height:200vh;
				display:block;
				background-color:#2d2d2d;
				opacity:0.9;
				z-index:-1;
				top:rem($quickbar);
				transition: opacity 0.3s, visibility 0.3s;
			}

			span {
				transition:0.4s linear;
				text-indent:-9999px;
				width:0;
				top:50%;
				transform:translate(-50%, -50%);

				&:before, &:after {
					top:50%;
					width:20px;
					transition:top 0.3s ease-in-out, transform 0.3s 0.2s linear;
				}

				&:before {
					transform:translate(-50%, -50%) rotate(45deg);
				};

				&:after {
					transform:translate(-50%, -50%) rotate(-45deg)
				};
			}

			span, span:before, span:after {
				content:"";
				position:absolute;
				display:block;
				background:$dark;
				height:2px;
				left:50%;
				transform-origin:center
			};
		};

		.contact {
			text-decoration: none;
			color:$dark;

			i {
				color:$dark;
			}
		}
	};
	
	#naviToggled {

		&:not(:checked) {

	 		& ~ * #navigation,
	 		& ~ #navigation {
				transform:translateX(100%);
	 		};

	 		& ~ #quickbar {
				
				label[for="naviToggled"] {

					span {
						width:20px;

						&:before, &:after {
							transform:translate(-50%, -50%);
							transition:top 0.3s 0.2s ease-in-out, transform 0.3s linear;
						};

						&:before {
							top:calc(50% - 5px);
						};

						&:after {
							top:calc(50% + 5px);
						};
					}

					&:after {
						opacity:0;
		 				visibility:hidden
					};
				}

	 		};
	 		
	 	};
	}
}