// Form styles
form.default {
	overflow: hidden;
	width: 100%;
	box-shadow:0 0 20px rgba(black,0.2);
	padding:rem($baseLineHeight) rem($baseLineHeight) 0 rem($baseLineHeight);

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
	}

	fieldset {
		border: none;
		margin: 0 0 rem($baseLineHeight) 0;

		legend {
			font-weight: 700;
			font-size: rem(20px);
			width: 100%;
			display: block;
			border-bottom: 2px solid $dark;
			padding:0 0 rem($baseGap) 0;
			margin:0 0 rem($baseGap) 0;
		}
	}

	label,
	input:not(#Datenschutz),
	textarea,
	select,
	button,
	[type="submit"] {
		min-height: 3.5rem;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input:not(#Datenschutz),
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;
		font-weight: 300;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		padding-top: 0.9rem;
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		width: 100%;
	}

	.checkboxContainer {
		display: flex;
		position: relative;
		margin: 0 0 30px 0;
		background:$light;
		z-index: 0;
		align-items: center;
		overflow:hidden;

		input {
			position: absolute;
			top: 35px;
			left: 13px;
			width: 1px;
			height: 1px;

			&:checked {

				+ label {

					&:before {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		label {
			background:$light;
			z-index: 1;
			letter-spacing: 0;
			display: flex;
			color:$dark;
			text-transform: none;
			position: relative;

			&:hover {
				cursor: pointer;
			};

			&:before, &:after {
				display: block;
				position: absolute;
			};

			&:after {
				top:19px;
				left:0;
				width: 25px;
				height: 25px;
				border:3px solid $border;
				border-radius: 3px;
				background:$light;
				content:'';
				z-index: 2;
			};

			&:before {
				top:19px;
				left:rem(5px);
				font-family: $iconFont;
				@extend .icon-check;
				color:$success;
				opacity: 0;
				visibility: hidden;
				z-index: 3;
				font-size: rem(16px);
			}

			span {
				padding-left: rem(40px);
				margin-top: 2px;
				display: block;
			}
		}
	}

	.selectboxContainer {

		select {
			padding:rem(16px) rem($baseGap);
			border:none;
			font-weight: 300;
			background:$light;
			border:1px solid $border;

			option {
				font-weight: 300;
				background:$light;
			}
		}
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
