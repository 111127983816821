.lightbox-inline {
}

.lightbox-external {
}

/* default colorbox hover */
[data-rel^="lightbox"], a.lightbox, a.modalGallery {
   	@extend .marginBottom;
    display: table;
    position: relative;
    text-decoration: none;
    width: 100%;
    z-index: 0;

    &[href*=qr] {
        width: auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }

    &:before {
        z-index: 2;
        @extend .icon;
        @extend .icon-search;
        background:$primary;
        color:$light;
        font-size: rem(20px);
        width:rem(50px);
        height: rem(50px);
        border-radius: 100%;
        display: inline-flex;
        justify-content:center;
        align-items:center;
        top:40%;
        left:50%;
        transform:translate(-50%,-50%);
        transition: top 0.4s, opacity 0.4s, visibility 0.4s;
    }

    &:after {
        transition: opacity 0.4s, visibility 0.4s;
        z-index: 1;
        content:'';
        background:rgba(black,0.3);
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }

    &:hover {

        &:before, &:after {
            opacity: 1;
            visibility: visible;
        }

        &:before {
            top:50%;
        }
    }
}